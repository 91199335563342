import React, { useEffect, useState } from "react";
import SideNav from "../../../Nav/SideNav";
import { Hidden } from "@material-ui/core";
import SideNavToggle from "../../../Nav/SidenavToggle";
import styles from "./style.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCardTwo = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getAllProducts();
    getAllContents();
  }, []);

  const getAllProducts = () => {
    axios({
      url: "https://backend.ahinternational.in/api/b2b/get-products-by-filter",
      method: "get",
    })
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllContents = () => {
    axios({
      url: "https://backend.ahinternational.in/api/contents/",
      method: "get",
    })
      .then((res) => {
        console.log(res);
        setContent(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddToContent = () => {
    if (product) {
      let heroCardOne = content.heroCardOne;
      let heroCardTwo = content.heroCardTwo;
      let heroCardThree = content.heroCardThree;

      axios({
        url: "https://backend.ahinternational.in/api/contents/" + content._id,
        method: "patch",
        data: {
          heroCardTwo: [
            ...heroCardTwo,
            { priority: -1, productId: product, title: title },
          ],
          heroCardOne,
          heroCardThree,
        },
      })
        .then((res) => {
          console.log(res);
          toast.success("Added Successfully.");
          navigate("/admin/content");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong.");
        });
    } else {
      toast.error("Please Select Product");
    }
  };

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className={styles.container}>
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Add Card 2 Content</h4>
        <div style={{ paddingLeft: "20px", marginTop: "50px" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <input
              type="text"
              style={{
                width: "300px",
                height: "40px",
                borderRadius: "10px",
                padding: "10px",
              }}
              value={title}
              placeholder="Enter Title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <select
              style={{ width: "300px", height: "40px", borderRadius: "10px" }}
              onChange={(e) => {
                setProduct(e.target.value);
              }}
            >
              <option selected disabled>
                Select Product
              </option>
              {products.map((val, index) => {
                return (
                  <option value={val._id} key={index}>
                    {val.productId}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            <button
              style={{
                border: "none",
                border: "1px solid #0e2d1c",
                background: "transparent",
                width: "100px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={() => navigate("/admin/content")}
            >
              Cancel
            </button>
            <button
              style={{
                border: "none",
                background: "#0e2d1c",
                color: "white",
                width: "100px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={AddToContent}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCardTwo;
