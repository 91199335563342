import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteClientById,
  getB2BUsers,
  getRetailUsers,
} from "../../Functions/auth";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";

const UserDetailModal = ({ setModalState, clientId, setReload }) => {
  const deleteUser = async () => {
    console.log("user is going to get deleted ");
    const deleteClientResponse = await deleteClientById(clientId);
    setReload((res) => !res);
    console.log(deleteClientResponse);
    setModalState(false);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const modal = (
    <div className="modal-container-for-user-deletion">
      <div className="modal-header">
        <h1>Message</h1>
        <div>
          <span className="modal-header-close-btn" onClick={closeModal}>
            close
          </span>
        </div>
      </div>
      <div className="modal-body">
        <> Are you sure You want to delete this record ?</>
      </div>
      <div className="modal-footer">
        <button className="button btn-danger m-2 mt-4" onClick={closeModal}>
          Cancel
        </button>
        <button
          className="button btn-success m-2 mt-4"
          onClick={() => {
            deleteUser();
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.getElementById("modal"));
};

const AdminClients = () => {
  const [users, setUsers] = useState([]);
  const [retail, setRetail] = useState([]);
  const [select, setSelect] = useState("");
  const [reload, setReload] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [clientId, setClientId] = useState("");
  const history = useNavigate();

  let dispatch = useDispatch();

  const getData = () => {
    getB2BUsers()
      .then((res) => {
        console.log(res.data.users);
        setUsers(res.data.users);
      })
      .catch((err) => console.log(err));

    getRetailUsers()
      .then((res) => setRetail(res.data.retail))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history("/admin");
    }
    getData();
  }, [reload]);

  useEffect(() => {
    console.log(users, "in state");
  }, [users]);

  const verifyUser = (user_id) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url:
            "https://backend.ahinternational.in/api/updateuserinfo/" + user_id,
          method: "PATCH",
          data: { b2bVerified: true },
        })
          .then((res) => {
            console.log(res);
            getData();
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      }),
      {
        loading: "Updating user...",
        success: "User updated successfully.",
        error: "Something went wrong while updating user.",
      }
    );
  };

  const pauseUser = (user_id, val) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url:
            "https://backend.ahinternational.in/api/updateuserinfo/" + user_id,
          method: "PATCH",
          data: { pause: val },
        })
          .then((res) => {
            console.log(res);
            getData();
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      }),
      {
        loading: "Updating user...",
        success: "User updated successfully.",
        error: "Something went wrong while updating user.",
      }
    );
  };

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3 scrollbar">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Clients</h4>

        <div class="container-fluid mt-4">
          <form>
            <select
              className="form-select mt-4 mb-5"
              aria-label="Default select example"
              onChange={(e) => {
                setSelect(e.target.value);
              }}
            >
              <option>Select Client Type</option>
              <option value="B2B">B2B Users</option>
              <option value="Retail">Retail Users</option>
            </select>
          </form>
        </div>
        {select === "B2B" ? (
          <>
            <h4 className="mt-4 content-subheading ml-2">B2B Clients</h4>
            <div class="container-fluid horizontal-scrollable">
              <table className="table table-bordered">
                <thead className="thead-light table-head-background">
                  <tr>
                    {/* <th scope="col">Role</th> */}
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company</th>
                    <th scope="col">Phone no.</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Department</th>
                    <th scope="col">Address</th>
                    <th scope="col">Approved</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {users !== null &&
                    users.map((user) => (
                      <tr>
                        {/* <td>{user.rol}</td> */}
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.company}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.desg}</td>
                        <td>{user.dep}</td>
                        <td>{user.address}</td>
                        <td>
                          {!user?.b2bVerified ? (
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                verifyUser(user._id);
                              }}
                              style={{
                                background: "green",
                                borderColor: "green",
                                marginRight: "10px",
                              }}
                            >
                              Verify
                            </button>
                          ) : (
                            <>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.79 15.17C10.59 15.17 10.4 15.09 10.26 14.95L7.84 12.53C7.55 12.24 7.55 11.76 7.84 11.47C8.13 11.18 8.61 11.18 8.9 11.47L10.79 13.36L15.09 9.06003C15.38 8.77003 15.86 8.77003 16.15 9.06003C16.44 9.35003 16.44 9.83003 16.15 10.12L11.32 14.95C11.18 15.09 10.99 15.17 10.79 15.17Z"
                                  fill="green"
                                />
                                <path
                                  d="M12 22.75C11.37 22.75 10.74 22.54 10.25 22.12L8.67 20.76C8.51 20.62 8.11 20.48 7.9 20.48H6.18C4.7 20.48 3.5 19.28 3.5 17.8V16.09C3.5 15.88 3.36 15.49 3.22 15.33L1.87 13.74C1.05 12.77 1.05 11.24 1.87 10.27L3.22 8.68C3.36 8.52 3.5 8.13 3.5 7.92V6.2C3.5 4.72 4.7 3.52 6.18 3.52H7.91C8.12 3.52 8.52001 3.37 8.68001 3.24L10.26 1.88C11.24 1.04 12.77 1.04 13.75 1.88L15.33 3.24C15.49 3.38 15.89 3.52 16.1 3.52H17.8C19.28 3.52 20.48 4.72 20.48 6.2V7.9C20.48 8.11 20.63 8.51 20.77 8.67L22.13 10.25C22.97 11.23 22.97 12.76 22.13 13.74L20.77 15.32C20.63 15.48 20.48 15.88 20.48 16.09V17.79C20.48 19.27 19.28 20.47 17.8 20.47H16.1C15.89 20.47 15.49 20.62 15.33 20.75L13.75 22.11C13.26 22.54 12.63 22.75 12 22.75ZM6.18 5.02C5.53 5.02 5 5.55 5 6.2V7.91C5 8.48 4.73 9.21 4.36 9.64L3.01 11.23C2.66 11.64 2.66 12.35 3.01 12.76L4.36 14.35C4.73 14.79 5 15.51 5 16.08V17.79C5 18.44 5.53 18.97 6.18 18.97H7.91C8.49 18.97 9.22 19.24 9.66 19.62L11.24 20.98C11.65 21.33 12.37 21.33 12.78 20.98L14.36 19.62C14.8 19.25 15.53 18.97 16.11 18.97H17.81C18.46 18.97 18.99 18.44 18.99 17.79V16.09C18.99 15.51 19.26 14.78 19.64 14.34L21 12.76C21.35 12.35 21.35 11.63 21 11.22L19.64 9.64C19.26 9.2 18.99 8.47 18.99 7.89V6.2C18.99 5.55 18.46 5.02 17.81 5.02H16.11C15.53 5.02 14.8 4.75 14.36 4.37L12.78 3.01C12.37 2.66 11.65 2.66 11.24 3.01L9.66 4.38C9.22 4.75 8.48 5.02 7.91 5.02H6.18Z"
                                  fill="green"
                                />
                              </svg>
                            </>
                          )}
                        </td>
                        <td style={{ display: "flex" }}>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              setModalState(true);
                              setClientId(user._id);
                            }}
                            style={{
                              height: "fit-content",
                              width: "fit-content",
                              padding: 0,
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 6.72998C20.98 6.72998 20.95 6.72998 20.92 6.72998C15.63 6.19998 10.35 5.99998 5.12004 6.52998L3.08004 6.72998C2.66004 6.76998 2.29004 6.46998 2.25004 6.04998C2.21004 5.62998 2.51004 5.26998 2.92004 5.22998L4.96004 5.02998C10.28 4.48998 15.67 4.69998 21.07 5.22998C21.48 5.26998 21.78 5.63998 21.74 6.04998C21.71 6.43998 21.38 6.72998 21 6.72998Z"
                                fill="red"
                              />
                              <path
                                d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z"
                                fill="red"
                              />
                              <path
                                d="M15.21 22.7501H8.79002C5.30002 22.7501 5.16002 20.8201 5.05002 19.2601L4.40002 9.19007C4.37002 8.78007 4.69002 8.42008 5.10002 8.39008C5.52002 8.37008 5.87002 8.68008 5.90002 9.09008L6.55002 19.1601C6.66002 20.6801 6.70002 21.2501 8.79002 21.2501H15.21C17.31 21.2501 17.35 20.6801 17.45 19.1601L18.1 9.09008C18.13 8.68008 18.49 8.37008 18.9 8.39008C19.31 8.42008 19.63 8.77007 19.6 9.19007L18.95 19.2601C18.84 20.8201 18.7 22.7501 15.21 22.7501Z"
                                fill="red"
                              />
                              <path
                                d="M13.66 17.25H10.33C9.91996 17.25 9.57996 16.91 9.57996 16.5C9.57996 16.09 9.91996 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z"
                                fill="red"
                              />
                              <path
                                d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
                                fill="red"
                              />
                            </svg>
                          </button>
                          {user?.pause ? (
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                pauseUser(user._id, false);
                              }}
                              style={{
                                height: "fit-content",
                                width: "fit-content",
                                padding: "5px 10px",
                                border: "none",
                                background: "green",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.97 22.75C6.04997 22.75 1.21997 17.93 1.21997 12C1.21997 6.07 6.04997 1.25 11.97 1.25C17.89 1.25 22.72 6.07 22.72 12C22.72 17.93 17.9 22.75 11.97 22.75ZM11.97 2.75C6.86997 2.75 2.71997 6.9 2.71997 12C2.71997 17.1 6.86997 21.25 11.97 21.25C17.07 21.25 21.22 17.1 21.22 12C21.22 6.9 17.07 2.75 11.97 2.75Z"
                                  fill="white"
                                />
                                <path
                                  d="M10.56 16.99C10.12 16.99 9.69999 16.88 9.32999 16.67C8.46999 16.17 7.98999 15.19 7.98999 13.91V10.56C7.98999 9.27999 8.45999 8.29999 9.31999 7.79999C10.18 7.29999 11.27 7.37999 12.38 8.01999L15.28 9.68999C16.39 10.33 17 11.23 17 12.23C17 13.22 16.39 14.13 15.28 14.77L12.38 16.44C11.76 16.81 11.13 16.99 10.56 16.99ZM10.56 8.96999C10.38 8.96999 10.21 9.00999 10.08 9.08999C9.69999 9.30999 9.48999 9.83999 9.48999 10.56V13.91C9.48999 14.62 9.69999 15.16 10.08 15.37C10.45 15.59 11.02 15.5 11.64 15.15L14.54 13.48C15.16 13.12 15.51 12.67 15.51 12.24C15.51 11.81 15.15 11.36 14.54 11L11.64 9.32999C11.24 9.08999 10.87 8.96999 10.56 8.96999Z"
                                  fill="white"
                                />
                              </svg>
                              Resume this account
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                pauseUser(user._id, true);
                              }}
                              style={{
                                height: "fit-content",
                                width: "fit-content",
                                padding: "5px 10px",
                                border: "none",
                                background: "rgb(235,147,63)",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.97 22.75C6.04997 22.75 1.21997 17.93 1.21997 12C1.21997 6.07 6.04997 1.25 11.97 1.25C17.89 1.25 22.72 6.07 22.72 12C22.72 17.93 17.9 22.75 11.97 22.75ZM11.97 2.75C6.86997 2.75 2.71997 6.9 2.71997 12C2.71997 17.1 6.86997 21.25 11.97 21.25C17.07 21.25 21.22 17.1 21.22 12C21.22 6.9 17.07 2.75 11.97 2.75Z"
                                  fill="white"
                                />
                                <path
                                  d="M10.01 15.9498H8.71C7.79 15.9498 7.25 15.4198 7.25 14.5298V9.4698C7.25 8.5798 7.8 8.0498 8.71 8.0498H10C10.92 8.0498 11.46 8.5798 11.46 9.4698V14.5298C11.47 15.4198 10.92 15.9498 10.01 15.9498ZM8.75 14.4498H9.97V9.5498H8.76L8.75 14.4498Z"
                                  fill="white"
                                />
                                <path
                                  d="M15.29 15.9498H14C13.08 15.9498 12.54 15.4198 12.54 14.5298V9.4698C12.54 8.5798 13.09 8.0498 14 8.0498H15.29C16.21 8.0498 16.75 8.5798 16.75 9.4698V14.5298C16.75 15.4198 16.2 15.9498 15.29 15.9498ZM14.03 14.4498H15.25V9.5498H14.04L14.03 14.4498Z"
                                  fill="white"
                                />
                              </svg>
                              Pause this account
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : select === "Retail" ? (
          <>
            <h4 className="mt-4 content-subheading ml-2">Retail Clients</h4>
            <div class="container-fluid horizontal-scrollable">
              <table className="table table-head-background table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone no.</th>
                    <th scope="col">Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {retail !== undefined &&
                    retail.map((retailUser) => (
                      <tr>
                        <td>{retailUser.name}</td>
                        <td>{retailUser.email}</td>
                        <td>{retailUser.phoneNumber}</td>
                        <td>{retailUser.address}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              setModalState(true);
                              setClientId(retailUser._id);
                              // deleteClientById(
                              // 	retailUser._id
                              // );
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {modalState ? (
        <UserDetailModal
          setModalState={setModalState}
          clientId={clientId}
          setReload={setReload}
        />
      ) : null}
    </div>
  );
};

export default AdminClients;
