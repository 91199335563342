import axios from "axios";

export const signup = async (
  name,
  email,
  phoneNumber,
  address,
  password,
  role,
  companyName,
  designation,
  department
) => {
  console.log(
    name,
    email,
    password,
    companyName,
    phoneNumber,
    designation,
    department,
    address,
    role
  );
  await axios.post("https://backend.ahinternational.in/api/auth/signup", {
    name,
    email,
    password,
    address,
    role,
  });
};

export const signin = async (email, password) => {
  return await axios.post(
    "https://backend.ahinternational.in/api/auth/signin",
    {
      email,
      password,
    }
  );
};

export const getUsers = async () => {
  return await axios.get("https://backend.ahinternational.in/api/all");
};

export const getB2BUsers = async () => {
  return await axios.get("https://backend.ahinternational.in/api/B2B");
};

export const getRetailUsers = async () => {
  return await axios.get("https://backend.ahinternational.in/api/retail");
};

export const getClientInfo = async (clientId) => {
  return await axios.get(
    `https://backend.ahinternational.in/api/client/${clientId}`
  );
};

export const deleteClientById = async (clientId) => {
  return await axios.delete(
    `https://backend.ahinternational.in/api/user/${clientId}`
  );
};
