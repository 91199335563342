import React, { useEffect } from "react";
import "../styles.css";
import { Link, useNavigate } from "react-router-dom";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";

const AdminDashboard = () => {
  const history = useNavigate();
  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history("/admin");
    }
  });

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <SideNavToggle /> <h4 className="mt-4 ml-2">Orders</h4>
        <div class="container-fluid">User Orders Table</div>
      </div>
    </div>
  );
};

export default AdminDashboard;
