import axios from "axios";

export const getOrders = async () => {
  return await axios.get(`https://backend.ahinternational.in/api/orders`);
};

export const getB2bOrders = async () => {
  return await axios.get("https://backend.ahinternational.in/api/b2b/orders");
};

export const getSingleOrder = async () => {
  return await axios.get(`https://backend.ahinternational.in/api/orders`);
};

export const createOrder = async (
  products,
  orderedBy,
  totalAmount,
  discount
) => {
  console.log(products);
  return await axios.post(
    `https://backend.ahinternational.in/api/order/`,
    { products, orderedBy, totalAmount, discount },
    {}
  );
};

export const filterOrder = async (reqBody) => {
  return await axios.post(
    `https://backend.ahinternational.in/api/order/`,
    reqBody,
    {}
  );
};

export const deleteOrderById = async (orderId) => {
  return await axios.delete(
    `https://backend.ahinternational.in/api/delete-order/${orderId}`
  );
};

export const deleteOrders = async (orderId) => {
  return await axios.delete(
    `https://backend.ahinternational.in/api/deleteOrders`
  );
};
