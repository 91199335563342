// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-undef
  importScripts("https://cdn.sheetjs.com/xlsx-0.20.2/package/dist/shim.min.js");
  // eslint-disable-next-line no-undef
  importScripts(
    "https://cdn.sheetjs.com/xlsx-0.20.2/package/dist/xlsx.full.min.js"
  );

  // eslint-disable-next-line no-restricted-globals
  self.addEventListener("message", async (e) => {
    const { file } = e.data;
    let json_data = [];

    try {
      // Read the file asynchronously

      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = new Uint8Array(e.target.result);

        // eslint-disable-next-line no-undef
        const wb = XLSX.read(bufferArray, { type: "array" });

        wb.SheetNames.forEach((sheetName) => {
          const worksheet = wb.Sheets[sheetName];
          // eslint-disable-next-line no-undef
          const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Filter out empty rows
          const nonEmptyRows = rows.filter((row) =>
            row.some((cell) => cell !== null && cell !== "")
          );

          const sheetData = nonEmptyRows.map((row) => {
            let rowData = {};
            row.forEach((cell, index) => {
              const header = rows[0][index];
              rowData[header] = cell;
            });
            return rowData;
          });

          json_data.push(...sheetData);
        });

        // const wsname = wb.SheetNames[0];
        // const ws = wb.Sheets[wsname];

        // // eslint-disable-next-line no-undef
        // const data = XLSX.utils.sheet_to_json(ws);

        json_data.splice(0, 1);

        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ type: "data", data: json_data });
      };

      fileReader.onerror = (error) => {
        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ type: "error", data: error.message });
      };
    } catch (error) {
      // Send error message back to the main thread
      // eslint-disable-next-line no-restricted-globals
      self.postMessage({ type: "error", data: error.message });
    }
  });
};
