/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { createProducts } from "../../Functions/products";
import CreateProduct from "../../Forms/CreateProduct";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";

import worker from "../../excel.worker";
import WebWorker from "../../WebWorker";

const AdminCreateProduct = () => {
  const history = useNavigate();
  const [select, setSelect] = useState("");
  const [products, setProducts] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState("");

  const webWorker = new WebWorker(worker);

  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history("/admin");
    }
  });

  const readExcel = async (file) => {
    setLoading(true);
    webWorker.postMessage({ file });

    webWorker.addEventListener("message", (event) => {
      console.log("message from web worker ::: ", event.data);
      if (event.data.type === "data") {
        setLoading(false);

        console.log(event.data.data.length);

        setProducts(event.data.data);
        toast.success("File Converted Successfully.");
      } else {
        setLoading(false);
        toast.error("Error while converting file.");
      }
    });
  };

  const handleSubmit = (e) => {
    console.log("handling submit");
    e.preventDefault();

    products.map((p) => {
      if (p.batch === "N") {
        p.batch = false;
      }
      if (p.inventory === "Y") {
        p.inventory = true;
      }
    });

    const chunkSize = 50;

    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          for (let i = 0; i < products.length; i += chunkSize) {
            const chunk = products.slice(i, i + chunkSize);
            await createProducts(chunk, type);
          }
          resolve(true);
        } catch (error) {
          reject(false);
        }
      }),
      {
        success: "Uploaded Successfully.",
        error: "Something went wrong while uploading.",
        pending: "Uploading .....",
      }
    );
  };

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideNav />
        <div id="page-content-wrapper" className="ml-3">
          <Hidden smDown>
            <SideNavToggle initialIcon={"cross"} />
          </Hidden>
          <Hidden mdUp>
            <SideNavToggle initialIcon={"bar"} />
          </Hidden>
          <h4 className="mt-3 content-heading ml-4">Add Products</h4>

          <div className="container-fluid">
            <select
              className="form-select mt-5 mb-5"
              aria-label="Default select example"
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
            >
              <option value="" selected disabled>
                Select Product Type
              </option>
              <option value="qr">Qr Products</option>
              <option value="b2b">B2B Products</option>
            </select>
            {(type === "qr" || type === "b2b") && (
              <form>
                <select
                  className="form-select mt-5 mb-5"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelect(e.target.value);
                  }}
                >
                  <option>Select Method</option>
                  <option value="Single Product">Add Single Product</option>
                  <option value="Excel Sheet">Add Excel Sheet</option>
                </select>
                {select === "Single Product" ? (
                  <CreateProduct type={type} />
                ) : select === "Excel Sheet" ? (
                  <div>
                    <label>Choose File</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        const file = e.target.files[0];
                        readExcel(file);
                      }}
                    />
                    <button onClick={handleSubmit} className="button mt-2">
                      Submit
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </form>
            )}
          </div>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,.4)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <span className="loader"></span>
          <h3 style={{ color: "white" }}>
            Please wait while converting the file.
          </h3>
        </div>
      )}
    </>
  );
};

export default AdminCreateProduct;
