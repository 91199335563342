import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { itemListReducer } from "./itemListReducer";
import { adminReducer } from "./adminReducer";
const rootReducer = combineReducers({
  user: userReducer,
  list: itemListReducer,
  admin: adminReducer,
});

export default rootReducer;
