import React, { useEffect, useState } from "react";
import logo from "../Images/brand-white.png";
import { useDispatch } from "react-redux";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";

const SideNav = () => {
  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history("/admin");
  };

  const history = useNavigate();

  return (
    <div className="border-end item-background" id="sidebar-wrapper">
      <div className="sidebar-heading border-bottom ">
        <img src={logo} alt="AH International" className="logoSideNav" />
      </div>
      <div className="list-group list-group-flush item-background">
        <Link
          to="/admin/add-product"
          className="list-group-item list-group-item-action item-background p-3"
        >
          Add Product
        </Link>
        <Link
          to="/admin/products"
          className="list-group-item list-group-item-action item-background p-3"
        >
          Products
        </Link>
        <Link
          to="/admin/orders"
          className="list-group-item list-group-item-action item-background p-3"
        >
          Orders
        </Link>
        <Link
          to="/admin/clients"
          className="list-group-item list-group-item-action item-background p-3"
        >
          Clients
        </Link>
        <Link
          to="/admin/content"
          className="list-group-item list-group-item-action item-background p-3"
        >
          Content
        </Link>
        <Link
          className="list-group-item list-group-item-action item-background p-3"
          onClick={handleLogout}
        >
          Logout
        </Link>
      </div>
    </div>
  );
};

export default SideNav;

{
  /*  */
}
