import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteProductById,
  getProducts,
  deleteAllProduct,
  getAllProducts,
  getAllProductBrands,
  getProductsByBrand,
  deleteByBrand,
} from "../../Functions/products";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { toast } from "react-toastify";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";
import "./styles/pagination.css";
import "./styles/modal.css";
import { style } from "@mui/system";
import axios from "axios";

const Pagination = ({ totalPage, onPage, changePage }) => {
  const array = [];

  array.push(1);

  if (onPage > 4 && onPage < totalPage - 3) {
    array.push("...");
    array.push(onPage - 2, onPage - 1, onPage, onPage + 1, onPage + 2);
  } else {
    if (onPage <= 4) array.push(2, 3, 4, 5, 6);
    else {
      array.push("...");
      array.push(totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1);
    }
  }

  if (onPage < totalPage - 3) {
    array.push("...");
  }

  array.push(totalPage);

  return (
    <div className="pagination-container">
      <div className="pagination-list">
        {onPage !== 1 && (
          <span
            className="previous"
            onClick={() => {
              changePage(onPage - 1);
            }}
          >
            &lt;
          </span>
        )}
        {array.map((val, index) => {
          if (isNaN(val)) {
            return <span key={index}>...</span>;
          } else {
            return (
              <span
                key={index}
                value={val}
                style={onPage === val ? { color: "red" } : {}}
                onClick={() => {
                  changePage(val);
                }}
              >
                {val}
              </span>
            );
          }
        })}
        {onPage !== totalPage && (
          <span
            className="next"
            onClick={() => {
              changePage(onPage + 1);
            }}
          >
            &gt;
          </span>
        )}
      </div>
    </div>
  );
};

const Modal = ({ setModalState, modalFor }) => {
  const [allBrands, setAllBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const limit = modalFor === "download" ? 3 : 1;
  var checkboxgroup = document.querySelectorAll("#labels input");

  console.log("value of modalfor is  : ", modalFor);

  for (var i = 0; i < checkboxgroup.length; i++) {
    checkboxgroup[i].onclick = function () {
      var checkedcount = 0;
      for (var i = 0; i < checkboxgroup.length; i++) {
        checkedcount += checkboxgroup[i].checked ? 1 : 0;
      }
      if (checkedcount > limit) {
        console.log("You can select maximum of " + limit + " checkbox.");
        alert("You can select maximum of " + limit + " checkbox.");
        this.checked = false;
      }
    };
  }

  const itemSelected = (item) => {
    if (selectedList.indexOf(item) === -1) {
      console.log("adding into the selected list");
      setSelectedList((prev) => {
        prev.push(item);
        return prev;
      });
    } else {
      console.log("removing item from the selected list");
      setSelectedList((prev) => {
        prev.splice(prev.indexOf(item), 1);
        return prev;
      });
    }
  };

  const deleteBrand = async () => {
    console.log("start deleting products", selectedList[0]);
    setLoading(true);

    deleteByBrand(selectedList[0])
      .then((res) => {
        console.log(" response is : ", res);
        toast.success(`${res.data.deletedCount} products deleted`);
      })
      .catch((res) => {
        toast.error("some error occured while deleting products");
      });
    setLoading(false);
  };

  const downloadAll = async () => {
    setLoading(true);
    console.log("sending value : ", selectedList);
    let allProductsList = await getProductsByBrand(selectedList);
    let allProducts = [];

    for (let i = 0; i < selectedList.length; i++) {
      allProducts.push(...allProductsList.data.productsArray[i]);
    }

    var zip = new JSZip();
    var count = 0;
    var zipFilename = "Pictures.zip";

    var urls = [];
    allProducts.map((product) => {
      urls.push({ url: product.qrCodeUrl, id: product.productId });
    });
    console.log(urls);

    urls.forEach(function (item, i) {
      var filename = item.id + " " + ".png";
      filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, "");

      // filename = product.productId + 'OR_CODE';
      // loading a file and add it in a zip file

      JSZipUtils.getBinaryContent(item.url, function (err, data) {
        if (err) {
          console.log("error occured : ", err);
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count == urls.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
            setLoading(false);
          });
        }
      });
    });
  };

  const closeModal = () => {
    setModalState(false);
  };

  const getAllBrandssList = () => {
    getAllProductBrands()
      .then((res) => {
        console.log("got the brand", res);
        setAllBrands(res.data.productBrandArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllBrandssList();
  }, []);

  const modal = (
    <div className="modal-container">
      <div className="modal-header">
        <h1>{modalFor === "download" ? "Download" : "Delete"}</h1>
        <div>
          <span className="modal-header-close-btn" onClick={closeModal}>
            close
          </span>
        </div>
      </div>
      <div className="modal-body">
        <>
          {allBrands.map((item) => {
            return (
              <label id="labels">
                <input
                  type="checkbox"
                  value={item}
                  style={
                    selectedList.length === 3 ? { pointerEvents: "none" } : {}
                  }
                  onChange={(e) => {
                    itemSelected(e.target.value);
                  }}
                />{" "}
                {item}
              </label>
            );
          })}
        </>
      </div>
      <div className="modal-footer">
        <button className="button btn-danger m-2 mt-4" onClick={closeModal}>
          Cancel
        </button>
        <button
          className="button btn-success m-2 mt-4"
          onClick={() => {
            if (modalFor === "download") {
              downloadAll();
            } else {
              deleteBrand();
            }
          }}
        >
          {loading
            ? "Loading..."
            : modalFor === "download"
            ? "Download"
            : "Delete"}
        </button>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.getElementById("modal"));
};

const AdminProducts = () => {
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const [onPage, setOnPage] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalState, SetModalState] = useState(false);
  const [modalFor, setModalFor] = useState("");

  const totalPage = Math.ceil(3204 / 18);

  const changePage = (val) => {
    setOnPage(val);
    window.scrollTo(0, 0);
  };

  const getProductsData = () => {
    getProducts(onPage)
      .then((res) => {
        console.log("res : ", res);
        // console.log(res.data.products);
        setProducts(res.data);
        // console.log(products);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history("/admin");
    }
    getProductsData();
  }, [onPage]);

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history("/admin");
  };

  function html_table_to_excel() {
    let binaryWS = XLSX.utils.json_to_sheet(products);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Products");

    // export your excel
    XLSX.writeFile(wb, "Products.xlsx");
  }

  // const downloadAll = async () => {
  // 	console.log('TEST');
  // 	setIsDownloading(true);
  // 	let allProducts = await getAllProducts();
  // 	allProducts = allProducts.data.products;

  // 	var zip = new JSZip();
  // 	var count = 0;
  // 	var zipFilename = 'Pictures.zip';

  // 	var urls = [];
  // 	allProducts.map((product) => {
  // 		urls.push({ url: product.qrCodeUrl, id: product.productId });
  // 	});
  // 	console.log(urls);

  // 	urls.forEach(function (item, i) {
  // 		var filename = item.id + ' ' + '.png';
  // 		filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '');

  // 		//filename = product.productId + 'OR_CODE';
  // 		// loading a file and add it in a zip file

  // 		JSZipUtils.getBinaryContent(item.url, function (err, data) {
  // 			if (err) {
  // 				console.log('error occured : ', err);
  // 				throw err; // or handle the error
  // 			}
  // 			zip.file(filename, data, { binary: true });
  // 			count++;
  // 			if (count == urls.length) {
  // 				zip.generateAsync({ type: 'blob' }).then(function (
  // 					content
  // 				) {
  // 					saveAs(content, zipFilename);
  // 				});
  // 				setIsDownloading(false);
  // 			}
  // 		});
  // 	});
  // };

  const handleDelete = (id) => {
    // console.log(id);
    deleteProductById(id)
      .then((res) => {
        // console.log(res);
        toast.success(`Product with id ${id} has been deleted`);
        getProductsData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideNav />
        <div id="page-content-wrapper" className="ml-3">
          <Hidden smDown>
            <SideNavToggle initialIcon={"cross"} />
          </Hidden>
          <Hidden mdUp>
            <SideNavToggle initialIcon={"bar"} />
          </Hidden>
          <h4 className="mt-3 content-heading ml-4">All Products</h4>
          <div class="container-fluid" style={{ marginTop: "18px" }}>
            <button
              className="button btn-danger m-2 mt-4"
              id="sidebarToggle"
              // onClick={() => {
              // 	deleteAllProduct();
              // 	getProductsData();
              // }}
              onClick={() => {
                setModalFor("delete");
                SetModalState(true);
              }}
            >
              Delete All
            </button>
            <button
              className="button btn-success m-2 mt-4"
              id="sidebarToggle"
              onClick={() => {
                setModalFor("download");
                SetModalState(true);
              }}
              style={isDownloading ? { pointerEvents: "none" } : {}}
            >
              {isDownloading ? "Loading..." : "Download All"}
            </button>
            {modalState && (
              <Modal setModalState={SetModalState} modalFor={modalFor} />
            )}
            <button
              className="float-right button m-2 mt-4"
              id="sidebarToggle"
              onClick={html_table_to_excel}
            >
              Export Excel Sheet
            </button>
          </div>
          <div class="container-fluid" style={{ marginTop: "18px" }}>
            <div className="row">
              {products.map((p) => (
                <div key={p._id} className="col-lg-4 col-md-6 mt-3">
                  <div className="card">
                    <div className="card-header">{p.productId}</div>
                    <div className="card-body d-flex justify-content-center">
                      <img src={p.qrCodeUrl} alt="QR CODE" className="p-1" />
                    </div>
                    <div className="card-footer">
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(p._id)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-success float-right"
                        onClick={() => saveAs(p.qrCodeUrl, p.productId)}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Pagination
        onPage={onPage}
        changePage={changePage}
        totalPage={totalPage}
      />
    </>
  );
};

export default AdminProducts;
