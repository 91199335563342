/* eslint-disable */
import "./App.css";
import {
  Switch,
  Route,
  Link,
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home";
import ExistingUser from "./Pages/ExistingUser";
import NewUser from "./Pages/NewUser";
import ScanProduct from "./Pages/ScanProducts";
import AdminRoute from "./Pages/Admin/AdminRoute";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminProducts from "./Pages/Admin/AdminProducts";
import AdminCreateProduct from "./Pages/Admin/AdminCreateProduct";
import AdminOrders from "./Pages/Admin/AdminOrders";
import AdminClients from "./Pages/Admin/AdminClients";
import { ToastContainer } from "react-toastify";
import item from "./Pages/itemList";
import Cart from "./Pages/Cart";
import { useState } from "react";
import OrderSingle from "./Pages/Admin/OrderSingle";
import AdminContent from "./Pages/Admin/AdminContent";
import AddCardOne from "./Pages/Admin/AdminContentAdd/AddCardOne";
import AddCardThree from "./Pages/Admin/AdminContentAdd/AddCardThree";
import AddCardTwo from "./Pages/Admin/AdminContentAdd/AddCardTwo";
import UpdateCardOne from "./Pages/Admin/AdminContentAdd/UpdateCardOne";
import UpdateCardTwo from "./Pages/Admin/AdminContentAdd/UpdateCardTwo";
import UpdateCardThree from "./Pages/Admin/AdminContentAdd/UpdateCardThree";
import OrderSingleB2b from "./Pages/Admin/OrderSingleB2b";

const App = () => {
  //Get current user somehow for storing it in the application

  const route = createBrowserRouter([
    { path: "/", element: <Navigate to="/admin/dashboard" /> },
    {
      path: "/admin",
      element: <AdminRoute />,
    },
    {
      path: "/admin/dashboard",
      element: <AdminDashboard />,
    },
    {
      path: "/admin/products",
      element: <AdminProducts />,
    },
    {
      path: "/admin/add-product",
      element: <AdminCreateProduct />,
    },
    {
      path: "/admin/orders",
      element: <AdminOrders />,
    },
    {
      path: "/admin/clients",
      element: <AdminClients />,
    },
    { path: "/admin/content", element: <AdminContent /> },
    { path: "/admin/content/add/c1", element: <AddCardOne /> },
    { path: "/admin/content/add/c2", element: <AddCardTwo /> },
    { path: "/admin/content/add/c3", element: <AddCardThree /> },
    { path: "/admin/content/update/c1/:id", element: <UpdateCardOne /> },
    { path: "/admin/content/update/c2/:id", element: <UpdateCardTwo /> },
    { path: "/admin/content/update/c3/:id", element: <UpdateCardThree /> },
    {
      path: "/admin/singleOrder",
      element: <OrderSingle />,
    },
    { path: "/admin/singleOrder/b2b", element: <OrderSingleB2b /> },
  ]);
  //

  return (
    <>
      <ToastContainer />
      <RouterProvider router={route} />
    </>
  );
};

export default App;
