import React, { useEffect, useState } from "react";
import SideNav from "../../Nav/SideNav";
import { Hidden } from "@material-ui/core";
import SideNavToggle from "../../Nav/SidenavToggle";
import styles from "./styles/adminContent.module.css";
import DraggableTable from "../../DraggableTable";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AdminContent = () => {
  const [section, setSection] = useState("1");
  const navigate = useNavigate();
  const [c1, setC1] = useState([]);
  const [c2, setC2] = useState([]);
  const [c3, setC3] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    getAllContent();
  }, []);

  const getAllContent = () => {
    axios({
      url: "https://backend.ahinternational.in/api/contents/",
      method: "GET",
    })
      .then((res) => {
        console.log(res.data[0]);
        setId(res.data[0]._id);
        setC1(res.data[0].heroCardOne.map((val) => ({ ...val, id: val._id })));
        setC2(res.data[0].heroCardTwo.map((val) => ({ ...val, id: val._id })));
        setC3(
          res.data[0].heroCardThree.map((val) => ({ ...val, id: val._id }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (val) => {
    toast.promise(
      new Promise((resolve, reject) => {
        let temp_c1 = [];
        let temp_c2 = [];
        let temp_c3 = [];

        if (section === "1") {
          temp_c1 = c1.filter((v) => {
            if (v._id === val._id) {
              return false;
            }
            return true;
          });
          temp_c2 = c2;
          temp_c3 = c3;
        } else if (section === "2") {
          temp_c1 = c1;
          temp_c2 = c2.filter((v) => {
            if (v._id === val._id) {
              return false;
            }
            return true;
          });
          temp_c3 = c3;
        } else if (section === "3") {
          temp_c1 = c1;
          temp_c2 = c2;
          temp_c3 = c3.filter((v) => {
            if (v._id === val._id) {
              return false;
            }
            return true;
          });
        }

        axios({
          url: "https://backend.ahinternational.in/api/contents/" + id,
          method: "PATCH",
          data: {
            heroCardOne: temp_c1,
            heroCardTwo: temp_c2,
            heroCardThree: temp_c3,
          },
        })
          .then((res) => {
            getAllContent();
            resolve(true);
          })
          .catch((err) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted successfully.",
        error: "Something went wrong.",
      }
    );
  };

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className={styles.container}>
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Content</h4>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "50px",
              paddingLeft: "20px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className={styles.tag1}
                style={
                  section === "1"
                    ? { background: "#0e2d1c", color: "white" }
                    : {}
                }
                onClick={() => setSection("1")}
              >
                Card 1
              </div>
              <div
                className={styles.tag2}
                style={
                  section === "2"
                    ? { background: "#0e2d1c", color: "white" }
                    : {}
                }
                onClick={() => setSection("2")}
              >
                Card 2
              </div>
              <div
                className={styles.tag3}
                style={
                  section === "3"
                    ? { background: "#0e2d1c", color: "white" }
                    : {}
                }
                onClick={() => setSection("3")}
              >
                Card 3
              </div>
            </div>
            <button
              style={{
                border: "none",
                background: "#0e2d1c",
                color: "white",
                width: "100px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={() => {
                if (section === "1") {
                  navigate("/admin/content/add/c1");
                }
                if (section === "2") {
                  navigate("/admin/content/add/c2");
                }
                if (section === "3") {
                  navigate("/admin/content/add/c3");
                }
              }}
            >
              Add
            </button>
          </div>
          <div
            className={styles.table_wrapper}
            style={{ paddingInline: "20px" }}
          >
            <DraggableTable
              head={["S.No.", "Title", ""]}
              body={
                section === "1"
                  ? c1
                  : section === "2"
                  ? c2
                  : section === "3"
                  ? c3
                  : []
              }
              body_keys={["sno", "title", "action"]}
              onSwap={() => {}}
              onDelete={handleDelete}
              onEdit={(val, v) => {
                console.log(val, v);
                if (section === "1") {
                  navigate("/admin/content/update/c1/" + val);
                }
                if (section === "2") {
                  navigate("/admin/content/update/c2/" + val);
                }
                if (section === "3") {
                  navigate("/admin/content/update/c3/" + val);
                }
              }}
              pageNo={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminContent;
