import { PagesOutlined } from "@material-ui/icons";
import axios from "axios";

export const createProduct = async (product, type) => {
  console.log("creating single product : ", product);
  return await axios.post(
    type === "qr"
      ? `https://backend.ahinternational.in/api/create-product`
      : type === "b2b"
      ? `https://backend.ahinternational.in/api/b2b/create-product`
      : "",
    { product }
  );
};

export const createProducts = async (products, type) => {
  return await axios.post(
    type === "qr"
      ? `https://backend.ahinternational.in/api/products/bulkupload`
      : type === "b2b"
      ? `https://backend.ahinternational.in/api/b2b/products/bulkupload`
      : "",
    { products: products }
  );
};

export const deleteByBrand = async (brand) => {
  return await axios.delete(
    "https://backend.ahinternational.in/api/delete-by-brand/",
    { data: { brand: brand } }
  );
};

export const getProducts = async (pageNumber = 1, numberOfProducts = 18) => {
  return await axios.post(
    `https://backend.ahinternational.in/api/get-product-by-count/`,
    {
      page: pageNumber,
      count: numberOfProducts,
    }
  );
};

export const getProductsByBrand = async (selectedList) => {
  console.log("making request for : ", selectedList);
  return await axios.post(
    "https://backend.ahinternational.in/api/get-products-by-brand",
    { brand: [...selectedList] }
  );
};

export const getAllProductBrands = async () => {
  return await axios.get(
    "https://backend.ahinternational.in/api/get-all-brands"
  );
};

export const getAllProducts = async () => {
  return await axios.get("https://backend.ahinternational.in/api/products/");
};

export const getProductById = async (productId) => {
  return await axios.get(
    `https://backend.ahinternational.in/api/product/${productId}`
  );
};

export const deleteProductById = async (productId) => {
  return await axios.delete(
    `https://backend.ahinternational.in/api/product/${productId}`
  );
};

export const deleteAllProduct = async () => {
  return await axios.delete(
    `https://backend.ahinternational.in/api/deleteAll/`
  );
};
