import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getOrders } from "../../Functions/orders";
import { useSelector, useDispatch } from "react-redux";
import { Hidden } from "@material-ui/core";
import * as XLSX from "xlsx";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { getClientInfo } from "../../Functions/auth";

const ClientName = ({ clientId }) => {
  const [client, setClient] = useState("");

  const getClientInfoRequest = async () => {
    if (clientId === undefined) {
      setClient("User Not Available");
    } else {
      const clientInfoResponse = await getClientInfo(clientId);
      setClient(clientInfoResponse.data.client.name);
    }
  };

  useEffect(() => {
    getClientInfoRequest();
  }, []);

  return client;
};

const OrderSingle = () => {
  const history = useNavigate();
  const [orders, setOrders] = useState([]);
  let dispatch = useDispatch();
  const { admin } = useSelector((state) => ({ ...state }));

  console.log("admin value is ::: ", admin);

  const [totalCost, setTotalCost] = useState(0);

  const calCulateCost = () => {
    let newtotalCost = 0;
    console.log(admin, "incal");
    for (let t of admin.products) {
      if (t.mrp) {
        newtotalCost =
          newtotalCost + parseInt(t.mrp) * parseInt(t.quantityBought);
      }
    }
    setTotalCost(newtotalCost);
  };

  useEffect(() => {
    console.log(admin, "single Order");
    if (admin.products !== null) {
      calCulateCost();
    }
  }, [admin]);

  const Orders = () => {
    setTimeout(() => {
      // getOrders().then((res)=>setOrders(res.data)).catch(err=>console.log(err));
    }, 5000);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history("/admin");
  };

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>{" "}
        <h4 className="mt-4 ml-2">Order ID: {admin._id}</h4>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="mt-4 ml-2">
              Ordered By: <ClientName clientId={admin.orderedBy} />
            </h4>
            <h4 className="mt-4 ml-2">
              Ordered Created on{" "}
              {new Date(admin.createdAt).toLocaleDateString()} at{" "}
              {new Date(admin.createdAt).toLocaleTimeString()}
            </h4>
          </div>
          <button
            className="button m-5 mt-4"
            id="sidebarToggle"
            onClick={() => {
              history("/admin/orders");
            }}
          >
            Back
          </button>
        </div>
        <br />
        <br />
        <div class="container-fluid">
          <table className="table table-bordered">
            <thead className="thead-light table-head-background">
              <tr>
                <th scope="col">Product Name</th>
                <th scope="col">Model No.</th>
                <th scope="col">Specification</th>
                <th scope="col">Brand</th>
                <th scope="col">Purchase Currency</th>
                <th scope="col">Cost Purchase Currency</th>
                <th scope="col">HSN Code</th>
                <th scope="col">Product Type</th>
                <th scope="col">Product Category</th>
                <th scope="col">Country of Origin</th>
                <th scope="col">Case Pack</th>
                <th scope="col">Quantity Bought</th>
                <th scope="col">MRP</th>
                <th scope="col">Total Cost</th>
              </tr>
              {admin.products.map((product) => (
                <tr>
                  <td>{product.subGroup2}</td>
                  <td>{product.productId}</td>
                  <td>{product.name}</td>
                  <td>{product.productGroup}</td>
                  <td>{product.purchaseCurrency}</td>
                  <td>{product.costPurchasePrice}</td>
                  <td>{product.hsnCode}</td>
                  <td>{product.productType}</td>
                  <td>{product.productCategory}</td>
                  <td>{product.countryOfOrigin}</td>
                  <td>{product.minimumOrderQuantity}</td>
                  <td>{product.quantityBought}</td>
                  <td>{product.mrp ? product.mrp : 0}</td>
                  <td>
                    {product.mrp ? product.mrp : 0 * product.quantityBought}
                  </td>
                </tr>
              ))}
            </thead>
          </table>
          <h4 className="mt-4 ml-2">Total Cost: {totalCost}</h4>
        </div>
      </div>
    </div>
  );
};

export default OrderSingle;
