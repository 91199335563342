import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getOrders } from "../../Functions/orders";
import { useSelector, useDispatch } from "react-redux";
import { Hidden } from "@material-ui/core";
import * as XLSX from "xlsx";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { getClientInfo } from "../../Functions/auth";

const ClientName = ({ clientId }) => {
  const [client, setClient] = useState("");

  const getClientInfoRequest = async () => {
    if (clientId === undefined) {
      setClient("User Not Available");
    } else {
      const clientInfoResponse = await getClientInfo(clientId);
      setClient(clientInfoResponse.data.client.name);
    }
  };

  useEffect(() => {
    getClientInfoRequest();
  }, []);

  return client;
};

const OrderSingleB2b = () => {
  const history = useNavigate();
  const { admin } = useSelector((state) => ({ ...state }));

  console.log("admin is ::: ", admin);

  const [totalCost, setTotalCost] = useState(0);

  const calCulateCost = () => {
    setTotalCost(admin.totalAmount);
  };

  useEffect(() => {
    if (admin.products !== null) {
      calCulateCost();
    }
  }, [admin]);

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>{" "}
        <h4 className="mt-4 ml-2">Order ID: {admin._id}</h4>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="mt-4 ml-2">
              Ordered By: <ClientName clientId={admin.orderedBy} />
            </h4>
            <h4 className="mt-4 ml-2">
              Ordered Created on{" "}
              {new Date(admin.createdAt).toLocaleDateString()} at{" "}
              {new Date(admin.createdAt).toLocaleTimeString()}
            </h4>
            <h4 className="mt-4 ml-2">
              Shipping Address : <br />
              <span style={{ paddingLeft: "10px" }}>
                {admin?.shippingAddress?.addressLine1
                  ? admin?.shippingAddress?.addressLine1 + ", "
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.shippingAddress?.addressLine2
                  ? admin?.shippingAddress?.addressLine2 + ", "
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.shippingAddress?.city
                  ? admin?.shippingAddress?.city + ", "
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.shippingAddress?.state
                  ? admin?.shippingAddress?.state + " -"
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.shippingAddress?.pincode
                  ? admin?.shippingAddress?.pincode
                  : ""}
              </span>
            </h4>
            <h4 className="mt-4 ml-2">
              Billing Address : <br />
              <span style={{ paddingLeft: "10px" }}>
                {admin.billingAddress?.addressLine1
                  ? admin?.billingAddress?.addressLine1 + ", "
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.billingAddress?.addressLine2
                  ? admin?.billingAddress?.addressLine2 + ", "
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.billingAddress?.city
                  ? admin?.billingAddress?.city + ", "
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.billingAddress?.state
                  ? admin?.billingAddress?.state + " -"
                  : ""}
              </span>
              <span style={{ paddingLeft: "10px" }}>
                {admin?.billingAddress?.pincode
                  ? admin?.billingAddress?.pincode
                  : ""}
              </span>
            </h4>
          </div>
          <button
            className="button m-5 mt-4"
            id="sidebarToggle"
            onClick={() => {
              history("/admin/orders");
            }}
            style={{ height: "50px" }}
          >
            Back
          </button>
        </div>
        <br />
        <br />
        <div class="container-fluid">
          <table className="table table-bordered">
            <thead className="thead-light table-head-background">
              <tr>
                <th scope="col">Product Name</th>
                <th scope="col">Model No.</th>
                <th scope="col">Specification</th>
                <th scope="col">Brand</th>
                <th scope="col">Purchase Currency</th>
                <th scope="col">Cost Purchase Currency</th>
                <th scope="col">HSN Code</th>
                <th scope="col">Product Type</th>
                <th scope="col">Product Category</th>
                <th scope="col">Country of Shipment</th>
                <th scope="col">Case Pack</th>
                <th scope="col">Quantity Bought</th>
                <th scope="col">MRP</th>
                <th scope="col">GST</th>
                <th scope="col">GST Amount</th>
                <th scope="col">Total Cost</th>
              </tr>
              {admin.products.map((product) => (
                <tr>
                  <td>{product.productId?.subGroup2}</td>
                  <td>{product?.productId.productId}</td>
                  <td>{product?.productId.productShortSpecification}</td>
                  <td>{product?.productId.brandName}</td>
                  <td>{product?.productId.purchaseCurrency}</td>
                  <td>{product?.productId.costPurchasePrice}</td>
                  <td>{product?.productId.hsnCode}</td>
                  <td>{product?.productId.productType}</td>
                  <td>{product?.productId.productSolution}</td>
                  <td>{product?.productId.countryOfShipment}</td>
                  <td>{product?.productId.salesCasePackQuantity}</td>
                  <td>{product?.quantity}</td>
                  <td>{product?.productId.mrp ? product.productId?.mrp : 0}</td>
                  <td>
                    {product?.productId?.gst
                      ? `${product?.productId?.gst}%`
                      : ""}
                  </td>
                  <td>
                    {product?.productId?.gst
                      ? `${
                          product.productId?.mrp *
                          (product?.productId?.gst / 100)
                        }`
                      : ""}
                  </td>
                  <td>
                    {product.productId?.mrp
                      ? product.productId?.mrp
                      : 0 * product?.quantity}
                  </td>
                </tr>
              ))}
            </thead>
          </table>
          <h4 className="mt-4 ml-2">Total Cost: {totalCost}</h4>
        </div>
      </div>
    </div>
  );
};

export default OrderSingleB2b;
