import React, { useEffect, useState } from "react";
import SideNav from "../../../Nav/SideNav";
import { Hidden } from "@material-ui/core";
import SideNavToggle from "../../../Nav/SidenavToggle";
import styles from "./style.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const UpdateCardTwo = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const params = useParams();
  console.log(params.id);

  const navigate = useNavigate();

  useEffect(() => {
    getAllProducts();
    getAllContents();
  }, []);

  const getAllProducts = () => {
    axios({
      url: "https://backend.ahinternational.in/api/b2b/get-products-by-filter",
      method: "get",
    })
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllContents = () => {
    axios({
      url: "https://backend.ahinternational.in/api/contents/",
      method: "get",
    })
      .then((res) => {
        setContent(res.data[0]);
        let temp = res.data[0];

        let c1 = temp.heroCardOne.filter((val) => val._id === params.id);
        let c2 = temp.heroCardTwo.filter((val) => val._id === params.id);
        let c3 = temp.heroCardThree.filter((val) => val._id === params.id);

        console.log(!!c1.length);
        console.log(!!c2.length);
        console.log(!!c3.length);

        if (!!c1.length) {
          setType("1");
          setTitle(c1[0]?.title);
          setProduct(c1[0]?.productId._id);
        }

        if (!!c2.length) {
          setType("2");
          setTitle(c2[0]?.title);
          setProduct(c2[0]?.productId._id);
        }

        if (!!c3.length) {
          setType("3");
          setTitle(c3[0]?.title);
          setProduct(c3[0]?.productId._id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddToContent = () => {
    if (product) {
      let heroCardOne = content.heroCardOne;
      let heroCardTwo = content.heroCardTwo;
      let heroCardThree = content.heroCardThree;

      if (type === "1") {
        heroCardOne = heroCardOne.map((val) => {
          if (val._id === params.id) {
            return { ...val, title, productId: product };
          }
          return val;
        });
      }

      if (type === "2") {
        heroCardTwo = heroCardTwo.map((val) => {
          if (val._id === params.id) {
            return { ...val, title, productId: product };
          }
          return val;
        });
      }

      if (type === "3") {
        heroCardThree = heroCardThree.map((val) => {
          if (val._id === params.id) {
            return { ...val, title, productId: product };
          }
          return val;
        });
      }

      axios({
        url: "https://backend.ahinternational.in/api/contents/" + content._id,
        method: "patch",
        data: {
          heroCardOne,
          heroCardTwo,
          heroCardThree,
        },
      })
        .then((res) => {
          console.log(res);
          toast.success("Updated Successfully.");
          navigate("/admin/content");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong.");
        });
    } else {
      toast.error("Please Select Product");
    }
  };

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className={styles.container}>
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Update Card 1 Content</h4>
        <div style={{ paddingLeft: "20px", marginTop: "50px" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <input
              type="text"
              style={{
                width: "300px",
                height: "40px",
                borderRadius: "10px",
                padding: "10px",
              }}
              value={title}
              placeholder="Enter Title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <select
              style={{ width: "300px", height: "40px", borderRadius: "10px" }}
              onChange={(e) => {
                setProduct(e.target.value);
              }}
              value={product}
            >
              <option selected disabled>
                Select Product
              </option>
              {products.map((val, index) => {
                return (
                  <option value={val._id} key={index}>
                    {val.productId}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            <button
              style={{
                border: "none",
                border: "1px solid #0e2d1c",
                background: "transparent",
                width: "100px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={() => navigate("/admin/content")}
            >
              Cancel
            </button>
            <button
              style={{
                border: "none",
                background: "#0e2d1c",
                color: "white",
                width: "100px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={AddToContent}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCardTwo;
