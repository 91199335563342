import React, { useState } from "react";
import data from "../states-and-districtsof_india.json";
import { signup } from "../Functions/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createProduct } from "../Functions/products";

const CreateProduct = ({ type }) => {
  const [name, setName] = useState("");
  const [productId, setProductId] = useState("");
  const [mrp, setMRP] = useState("");
  const [sellTax, setSellTax] = useState("");
  const [gst, setGST] = useState("");
  const [productGroup, setProductGroup] = useState("");
  const [purchaseCurrency, setPurchaseCurrency] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [netPrice, setNetPrice] = useState("");
  const [purchaseTax, setPurchaseTax] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [productType, setProductType] = useState("");
  const [subGroup1, setSubGroup1] = useState("");
  const [subGroup2, setSubGroup2] = useState("");
  const [countryOfOrigin, setCountryOfOrigin] = useState("");
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");

  let history = useNavigate();
  let dispatch = useDispatch();
  const onHandleSumbit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      productId === "" ||
      mrp === "" ||
      productGroup === "" ||
      purchaseCurrency === ""
    ) {
      toast.error("Please fill all the fields");
    } else {
      createProduct(
        {
          name,
          productId,
          mrp,
          sellTax,
          gst,
          sellTax,
          productGroup,
          productType,
          subGroup1,
          subGroup2,
          countryOfOrigin,
          minimumOrderQuantity,
          hsnCode,
          purchaseTax,
          discount,
          netPrice,
        },
        type
      )
        .then((res) => {
          if (res.data.message === "Product was registered successfully!") {
            toast.success(res.data.message);
            setName("");
            setProductId("");
            setMRP("");
            setSellTax("");
            setGST("");
            setProductId("");
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div>
      <form>
        <label>Product Name</label>
        <input
          className="form-control"
          value={subGroup2}
          onChange={(e) => setSubGroup2(e.target.value)}
          placeholder="Product Name"
        />
        <br />
        <label>Product Name/Specifications</label>
        <input
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Product Name/Specifications"
        />
        <br />
        <label>Model No</label>
        <input
          className="form-control"
          value={productId}
          onChange={(e) => setProductId(e.target.value)}
          placeholder="Model No"
        />
        <br />

        <label>MRP</label>
        <input
          className="form-control"
          value={mrp}
          onChange={(e) => setMRP(e.target.value)}
          placeholder="MRP"
        />
        <br />

        <label>Product Type</label>
        <input
          className="form-control"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
          placeholder="Product Type"
        />
        <br />

        <label>Sales Tax</label>
        <input
          className="form-control"
          value={sellTax}
          onChange={(e) => setSellTax(e.target.value)}
          placeholder="Sales Tax"
        />
        <br />

        <label>Brand</label>
        <input
          className="form-control"
          value={productGroup}
          onChange={(e) => setProductGroup(e.target.value)}
          placeholder="Brand"
        />
        <br />
        <label>GST</label>
        <input
          className="form-control"
          value={gst}
          onChange={(e) => setGST(e.target.value)}
          placeholder="GST"
        />
        <br />

        <label>Purchase Currency</label>
        <input
          className="form-control"
          value={purchaseCurrency}
          onChange={(e) => setPurchaseCurrency(e.target.value)}
          placeholder="Purchase Currency"
        />
        <br />

        <label>Cost Purchase Price</label>
        <input
          className="form-control"
          value={purchasePrice}
          onChange={(e) => setPurchasePrice(e.target.value)}
          placeholder="Cost Purchase Price"
        />
        <br />

        <label>Discount</label>
        <input
          className="form-control"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          placeholder="Discount"
        />
        <br />

        <label>Net Price</label>
        <input
          className="form-control"
          value={netPrice}
          onChange={(e) => setNetPrice(e.target.value)}
          placeholder="Net Price"
        />
        <br />

        <label>Purchase Tax</label>
        <input
          className="form-control"
          value={purchaseTax}
          onChange={(e) => setPurchaseTax(e.target.value)}
          placeholder="Purchase Tax"
        />
        <br />

        <label>Sell Tax</label>
        <input
          className="form-control"
          value={sellTax}
          onChange={(e) => setGST(e.target.value)}
          placeholder="Sell Tax"
        />
        <br />

        <label>HSN Code</label>
        <input
          className="form-control"
          value={hsnCode}
          onChange={(e) => setHsnCode(e.target.value)}
          placeholder="HSN Code"
        />
        <br />

        <label>Sub Group</label>
        <input
          className="form-control"
          value={subGroup1}
          onChange={(e) => setSubGroup1(e.target.value)}
          placeholder="Sub Group"
        />
        <br />

        <label>Country of Origin</label>
        <input
          className="form-control"
          value={countryOfOrigin}
          onChange={(e) => setCountryOfOrigin(e.target.value)}
          placeholder="Country of Origin"
        />
        <br />

        <label>Mininum Order Quantity / Case Packs</label>
        <input
          className="form-control"
          value={minimumOrderQuantity}
          onChange={(e) => setMinimumOrderQuantity(e.target.value)}
          placeholder=">Mininum Order Quantity / Case Packs"
        />
        <br />
        <button onClick={onHandleSumbit} className="button mt-3">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateProduct;
