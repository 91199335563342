import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteOrderById,
  deleteOrders,
  getB2bOrders,
  getOrders,
} from "../../Functions/orders";
// import DateFnsUtils from "@date-io/date-fns";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";
import { getClientInfo } from "../../Functions/auth";

const UserDetailModal = ({ setModalState, userDetails, clientId }) => {
  const [clientDetails, setClientDetails] = useState({});
  const closeModal = () => {
    setModalState(false);
  };

  const getClientInfoRequest = async () => {
    const res = await getClientInfo(clientId);
    setClientDetails(res.data.client);
  };

  useEffect(() => {
    console.log("user effect is running");
    getClientInfoRequest();
  }, []);

  console.log("userDetails : ", userDetails);

  return ReactDOM.createPortal(
    <div className="modal-container">
      <div className="modal-header">
        <h1>User Details</h1>
        <div>
          <span className="modal-header-close-btn" onClick={closeModal}>
            close
          </span>
        </div>
      </div>
      <div className="modal-body">
        Role:{clientDetails.role}
        <br />
        name : {clientDetails.name}
        <br />
        email : {clientDetails.email}
        <br />
        Company : {clientDetails.companyName}
        <br />
        Phone : {clientDetails.phoneNumber}
        <br />
        Designation : {clientDetails.designation}
      </div>
      <div className="modal-footer">
        <button className="button btn-danger m-2 mt-4" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

const ClientName = ({ clientId }) => {
  const [client, setClient] = useState("");
  const [modalState, setModalState] = useState(false);

  const getClientInfoRequest = async () => {
    if (clientId === undefined) {
      setClient("User Not Available");
    } else {
      const clientInfoResponse = await getClientInfo(clientId);
      setClient(clientInfoResponse.data.client.name);
    }
  };

  useEffect(() => {
    console.log("user effect is running");
    getClientInfoRequest();
  }, []);

  return (
    <>
      <span
        onClick={() => {
          setModalState(true);
          console.log("user is clicked");
        }}
      >
        {client}
      </span>
      {modalState ? (
        <UserDetailModal setModalState={setModalState} clientId={clientId} />
      ) : null}
    </>
  );
};

const AdminOrders = () => {
  const history = useNavigate();
  const [orders, setOrders] = useState([]);
  const [select, setSelect] = useState("");
  const [b2bOrders, setB2bOrders] = useState([]);
  // const [modalState, setModalState] = useState(false);

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let dispatch = useDispatch();

  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history("/admin");
    }
    getOrdersData();
    getB2BOrderData();
  }, []);

  const getB2BOrderData = () => {
    getB2bOrders()
      .then((res) => {
        console.log("b2b orders", res.data);
        setB2bOrders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrdersData = () => {
    getOrders()
      .then((res) => {
        // console.log(res, "=> response");
        setOrders(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  const Orders = () => {
    setTimeout(() => {
      // getOrders().then((res)=>setOrders(res.data)).catch(err=>console.log(err));
    }, 5000);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history("/admin");
  };

  const html_table_to_excel = (products, orderedBy, totalAmount, orderId) => {
    let newProductList = [];

    products.map((product) => {
      newProductList.push({
        orderId: orderId,
        ...product,
        orderedBy: orderedBy,
        totalAmount: totalAmount,
      });
    });

    let binaryWS = XLSX.utils.json_to_sheet(newProductList);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Order");

    // export your excel
    XLSX.writeFile(wb, `${orderId}.xlsx`);
  };

  const html_table_to_excel_b2b = (
    products,
    orderedBy,
    orderId,
    billingAddress,
    shippingAddress,
    totalAmount
  ) => {
    let newProductList = [];

    console.log("billing Address : ", billingAddress);
    console.log("shipping Address : ", shippingAddress);

    products.map((product) => {
      newProductList.push({
        orderId: orderId,
        ...product,
        billingAddress: [
          billingAddress?.addressLine1,
          billingAddress?.addressLine2,
          billingAddress?.city,
          billingAddress?.state,
          billingAddress?.pincode,
        ]
          ?.filter((val) => val)
          .join(", "),
        shippingAddress: [
          shippingAddress?.addressLine1,
          shippingAddress?.addressLine2,
          shippingAddress?.city,
          shippingAddress?.state,
          shippingAddress?.pincode,
        ]
          ?.filter((val) => val)
          .join(", "),
        orderedBy: orderedBy,
        totalAmount: totalAmount,
      });
    });

    let binaryWS = XLSX.utils.json_to_sheet(newProductList);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Order");

    // export your excel
    XLSX.writeFile(wb, `${orderId}.xlsx`);
  };

  return (
    <div className="d-flex" id="wrapper">
      {console.log(orders)}

      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Orders</h4>

        <div class="container-fluid mt-4">
          <form>
            <select
              className="form-select mt-4 mb-5"
              aria-label="Default select example"
              onChange={(e) => {
                setSelect(e.target.value);
              }}
            >
              <option>Select Store Type</option>
              <option value="B2B">Online Store</option>
              <option value="Retail">Offline Store</option>
            </select>
          </form>
          {select === "Retail" ? (
            <>
              <div
                class="container-fluid"
                style={{ marginTop: "18px", padding: 0 }}
              >
                <button
                  className="button btn-danger mb-3"
                  id="sidebarToggle"
                  onClick={() => {
                    deleteOrders();
                    getOrdersData();
                  }}
                >
                  Delete All
                </button>
              </div>
              {/* <select
                className="form-select mt-2 mr-3 mb-5"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                aria-label="Default select example"
                onChange={(e) => {}}
              >
                <option>Select Product Group</option>
                <option value="B2B">JVD</option>
                <option value="Retail">Arial</option>
              </select>
              <select
                className="form-select mt-2 mr-3 mb-5"
                aria-label="Default select example"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                onChange={(e) => {}}
              >
                <option>Second Product Category</option>
                <option value="B2B">Category 1</option>
                <option value="Retail">Category 2</option>
              </select>
              <select
                className="form-select mt-2 mr-3 mb-5"
                aria-label="Default select example"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                onChange={(e) => {}}
              >
                <option>Third Product Category</option>
                <option value="B2B">Category 1</option>
                <option value="Retail">Category 2</option>
              </select>
              <select
                className="form-select mt-2 mr-3 mb-5"
                aria-label="Default select example"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                onChange={(e) => {}}
              >
                <option>Select Duration</option>
                <option value="B2B">Yesterday</option>
                <option value="Retail">Last 3 days</option>
                <option value="Retail">Last week</option>
                <option value="Retail">Last 2 week</option>
                <option value="Retail">Last month</option>
                <option value="Retail">Last quater</option>
                <option value="Retail">Last year</option>
              </select>
 */}
              {/* <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date picker inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              /> */}
              <table className="table table-bordered">
                <thead className="thead-light table-head-background">
                  <tr>
                    <th scope="col">Order_Id</th>
                    <th scope="col">Ordered_By</th>
                    <th scope="col">View Order</th>
                    <th scope="col">Export Order</th>
                    <th scope="col">Total amount</th>
                    <th scope="col">Ordered at</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr>
                      <td>
                        <span
                          style={{
                            fontFamily: "helvetica",
                            fontWeight: "200",
                          }}
                        >
                          {order._id}
                        </span>
                      </td>
                      <td>
                        <ClientName clientId={order.orderedBy} />
                      </td>
                      <td>
                        <button
                          className="button"
                          onClick={() => {
                            dispatch({
                              type: "SINGLE_ORDER",
                              payload: order,
                            });
                            history("/admin/singleOrder");
                          }}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="button"
                          onClick={() => {
                            html_table_to_excel(
                              order.products,
                              order.orderedBy,
                              order.totalAmount,
                              order._id
                            );
                          }}
                        >
                          Export Order
                        </button>
                      </td>
                      <td>
                        <span
                          style={{
                            fontFamily: "helvetica",
                          }}
                        >
                          {typeof order.totalAmount !== "undefined"
                            ? order.totalAmount
                            : 0}
                        </span>
                      </td>
                      <td>
                        on {new Date(order.createdAt).toLocaleDateString()} at{" "}
                        {new Date(order.createdAt).toLocaleTimeString()}
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            deleteOrderById(order._id).then((res) => {
                              getOrdersData();
                            });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : select === "B2B" ? (
            <table className="table table-bordered">
              <thead className="thead-light table-head-background">
                <tr>
                  <th scope="col">Order_Id</th>
                  <th scope="col">Ordered_By</th>
                  <th scope="col">View Order</th>
                  <th scope="col">Export Order</th>
                  <th scope="col">Total amount</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Ordered at</th>
                </tr>
              </thead>
              <tbody>
                {b2bOrders.map((order) => (
                  <tr>
                    <td>
                      <span
                        style={{
                          fontFamily: "helvetica",
                          fontWeight: "200",
                        }}
                      >
                        {order._id}
                      </span>
                    </td>
                    <td>
                      <ClientName clientId={order.orderedBy} />
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          dispatch({
                            type: "SINGLE_ORDER",
                            payload: order,
                          });
                          history("/admin/singleOrder/b2b");
                        }}
                      >
                        View
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          html_table_to_excel_b2b(
                            order.products,
                            order.orderedBy,
                            order._id,
                            order.billingAddress,
                            order.shippingAddress,
                            order.totalAmount
                          );
                        }}
                      >
                        Export Order
                      </button>
                    </td>
                    <td>
                      <span
                        style={{
                          fontFamily: "helvetica",
                        }}
                      >
                        {typeof order.totalAmount !== "undefined"
                          ? order.totalAmount
                          : 0}
                      </span>
                    </td>
                    <td>
                      on {new Date(order.createdAt).toLocaleDateString()} at{" "}
                      {new Date(order.createdAt).toLocaleTimeString()}
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          deleteOrderById(order._id).then((res) => {
                            getOrdersData();
                          });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
